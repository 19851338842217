import React, { useEffect, useState } from "react";
import {
  APPRECIATION_IMAGE_,
  FORM_BACKGROUND,
} from "../../../helpers/image_constants";
import { Button, Form, Input, Modal, Select } from "antd";
import Title from "../articles_form/admin_article_form/title";
import SubTitle from "../articles_form/admin_article_form/sub_title";
import { useTranslation } from "react-i18next";
// import Content from "../articles_form/admin_article_form/content";
// import SpreadNews from "../articles_form/admin_article_form/spread_news";
// import ShortStory from "../articles_form/admin_article_form/short_story";
// import Source from "../articles_form/admin_article_form/source";
import {
  dynamic_clear,
  dynamic_request,
  useDynamicSelector,
} from "../../../services/redux";
import { useDispatch } from "react-redux";
// import { showToast } from "../../../helpers/functions";
// import ConfettiAnimation from "../widgets/result";
import { get_all_languages_query } from "@src/services/graphql/graphql_home";
import { query_fact_check_preview } from "@src/services/graphql/graphql_fact_check";
import { ROUTES } from "@src/route/my_routes";
import { useNavigate, useParams } from "react-router-dom";
import SpreadNews from "../articles_form/admin_article_form/spread_news";
import AppreciationModal from "../custom_components/appreciation_modal/appreciation_modal";
import { useAPIRequest } from "@src/helpers/hooks";
import ImageUpload from "../articles_form/admin_article_form/image_upload";
import {
  mutation_create_article,
  query_get_article_preview,
} from "@src/services/graphql/graphql_articles";
import RichText from "@src/views/ui/rich_text";
import Content from "./content";

const ArticleCheckContribution = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [show_modal, set_show_modal] = useState(false);
  const [create_status, set_create_status] = useState(false);
  const [content, set_content] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    status: create_article_status,
    error: create_article_error,
    loading: create_article_loading,
  } = useDynamicSelector("create_article");
  const get_one_factCheck_details = useDynamicSelector("get_article");

  const { items: system_languages } = useDynamicSelector(
    "get_all_system_languages"
  );
  const create_article_api = useAPIRequest(
    "create_article",
    mutation_create_article
  );
  const get_all_languages = useAPIRequest(
    "get_all_system_languages",
    get_all_languages_query
  );
  const get_one_fact_check = useAPIRequest(
    "get_article",
    query_get_article_preview
  );

  useEffect(() => {
    get_all_languages({}, true);
  }, []);

  useEffect(() => {
    if (id) {
      get_one_fact_check({ id: id }, true);
    }
  }, [id]);
  useEffect(() => {
    if (get_one_factCheck_details && id) {
      form.setFieldsValue({
        ...get_one_factCheck_details,
        thumbnail_img: get_one_factCheck_details?.thumbnail_img
          ? [
              {
                name: "view",
                uid: 1,
                url: get_one_factCheck_details?.thumbnail_img,
                status: "done",
              },
            ]
          : [],
      });
      set_content(get_one_factCheck_details?.content);
    }
  }, [id, get_one_factCheck_details]);
  useEffect(() => {
    if (create_article_status === "Success") {
      set_show_modal(true);
      set_create_status(true);
      setTimeout(() => {
        set_show_modal(false);
        set_create_status(false);
        navigate(ROUTES.USER_ARTICLES);
      }, 10000);

      dispatch(dynamic_clear("create_article"));
    } else if (create_article_error?.message) {
      dispatch(dynamic_clear("create_article"));
    }
  }, [create_article_status, create_article_error]);

  const handle_back = () => {
    navigate(ROUTES.USER_PROFILE);
  };

  const handle_submit = (values) => {
    create_articles(values);
  };

  const create_articles = (values) => {
    let data = {
      ...values,
      party_tags: values?.party_tag_id,
      thumbnail_img: values?.thumbnail_img?.file?.url,
      is_fact: values?.is_fact,
      content: content?.html,
      content_json: content?.json,
    };
    delete data.party_tag_id;
    let variables = {
      json: data,
    };
    create_article_api(variables, true);
  };

  return (
    <div
      style={{
        position: "relative",
        backgroundImage: `url(${FORM_BACKGROUND})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          opacity: 0.1,
        }}
      ></div>
      <div className="user_create_article_form_container">
        <h2 className="form_title">
          {!id ? t("add_article") : t("view_article")}
        </h2>
        <Form
          id="article_form"
          form={form}
          layout="vertical"
          onFinish={handle_submit}
          disabled={id ? true : false}
        >
          <Title color={"#ffffff"} />
          <SubTitle color={"#ffffff"} />
          <Form.Item
            label={<span style={{ color: "white" }}>Language</span>}
            name="language_id"
            rules={[{ required: true, message: "Language is required " }]}
          >
            <Select>
              {system_languages?.map((option, index) => (
                <Select.Option key={option.id} value={option.id}>
                  {option.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name={"perma_link"}
            label={<span style={{ color: "white" }}>Perma Link</span>}
          >
            <Input
              field={"perma_link"}
              rules={[{ required: true, message: "Perma link is required " }]}
            />
          </Form.Item>
          <ImageUpload />
          <Content set_content={set_content} content={content} />
          <div
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            {/* <SpreadNews
              color={"#ffffff"}
              name={"content"}
              label={t("Content")}
              set_content={set_content}
              content={content}
              is_disabled={id ? true : false}
            /> */}
            <div className="fact_check_create_form_footer">
              <Button onClick={handle_back} danger disabled={false}>
                {t("back_contribution_form")}
              </Button>
              {!id && (
                <Button
                  htmlType="submit"
                  type="primary"
                  loading={create_article_loading}
                  disabled={false}
                >
                  {t("submit")}
                </Button>
              )}
            </div>
          </div>
        </Form>
      </div>
      <AppreciationModal
        subtitle={t("article_created_subtitle")}
        message={t("article_created_content")}
        appreciation_modal_open={show_modal}
        set_appreciation_modal={set_show_modal}
      />
    </div>
  );
};

export default ArticleCheckContribution;
