import { Button, Col, Divider, Form, Row, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import NameInput from "./name_input";
import ContactNo from "./contact_no";
import Email from "./email";
import FeedbackMsg from "./feedback_msg";
import { useTranslation } from "react-i18next";
import ContactCard from "./contact_card";
import { LuMailPlus } from "react-icons/lu";
import { FaPhoneAlt } from "react-icons/fa";
import { useAPIRequest } from "@src/helpers/hooks";
import { submit_feedback_mutation } from "@src/services/graphql/graphql_contact_us";
import { dynamic_clear, useDynamicSelector } from "@src/services/redux";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { retrieve_item } from "@src/helpers/cryptos";

const ContactUsForm = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user_id = retrieve_item("user_id");
  const name = retrieve_item("name");
  const mobile = retrieve_item("mobile");
  const email = retrieve_item("email");
  const [show_sent_sms_popup, set_show_sent_sms_popup] = useState(false);

  const { status, error, loading } = useDynamicSelector("create_user_query");
  let submit_feedback_request = useAPIRequest(
    "create_user_query",
    submit_feedback_mutation
  );

  useEffect(() => {
    if (status === "Success") {
      set_show_sent_sms_popup(true);
      setTimeout(() => {
        set_show_sent_sms_popup(false);
      }, 5000);
    }
  }, [status]);

  useEffect(() => {
    if (status === "Success") {
      // toast.success("Message Sent Successfully");
      form.resetFields("");
      dispatch(dynamic_clear("create_user_query"));
    } else if (error) {
      // toast.error(error?.message);
      dispatch(dynamic_clear("create_user_query"));
    }
  }, [status, error]);

  useEffect(() => {
    if (user_id) {
      form.setFieldsValue({ first_name: name, mobile: mobile, email: email });
    }
  }, [user_id, status]);

  const on_submit_feedback = (values) => {
    submit_feedback_request(
      {
        json: {
          data: {
            ...values,
            mobile: String(values?.mobile),
          },
        },
      },
      true
    );
  };

  return (
    <Form form={form} onFinish={on_submit_feedback}>
      <Row gutter={16}>
        <Col span={12}>
          <NameInput />
        </Col>
        <Col span={12}>
          <ContactNo />
        </Col>
      </Row>
      <Email />
      <FeedbackMsg />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Form.Item>
          <Tooltip
            title={"Message Sent Successfully"}
            id="primary-card-tooltip"
            open={show_sent_sms_popup}
            onOpen={() => set_show_sent_sms_popup(true)}
            onClose={() => set_show_sent_sms_popup(false)}
          >
            <Button
              className="form_submit_button"
              htmlType="submit"
              loading={loading}
            >
              {t("submit")}
            </Button>
          </Tooltip>
        </Form.Item>
      </div>
      <div className="contact_us_divider_wrapper">
        <Divider className="contact_us_divider">
          <text className="divider_text">{t("or")}</text>
        </Divider>
      </div>
      <div className="contact_info">
        <ContactCard
          icon={<FaPhoneAlt />}
          type="tel:"
          contact_info={"9004932435"}
        />
        <ContactCard
          icon={<LuMailPlus />}
          type="mailto:"
          contact_info={"youturnmedia@gmail.com"}
        />
      </div>
    </Form>
  );
};

export default ContactUsForm;
