import { retrieve_item } from "@src/helpers/cryptos";
import {
  date_format,
  ensure_https,
  split_featured_content,
} from "@src/helpers/functions";
import { BROKEN_IMAGE } from "@src/helpers/image_constants";
import { ROUTES } from "@src/route/my_routes";
import { useDynamicSelector } from "@src/services/redux";
import { Button } from "antd";
import { startCase } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { LuEye } from "react-icons/lu";
import { useNavigate } from "react-router-dom";

const FeaturedUserContributionImageContainer = (props) => {
  const { featured_user_contribution } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  let language = retrieve_item("language");

  const image_url = ensure_https(
    featured_user_contribution?.[0]?.thumbnail_img
  );

  const navigate_to_details_page = () => {
    if (featured_user_contribution?.[0]?.record_type === "fact_check") {
      const url = `${ROUTES.SINGLE_FACT_CHECK}/${featured_user_contribution?.[0]?.perma_link}.html`;
      return url;
      // window.open(url, "_blank");
    } else {
      const url = `${ROUTES.SINGLE_ARTICLE}/${featured_user_contribution?.[0]?.perma_link}.html`;
      return url;
      // window.open(url, "_blank");
    }
  };
  const url = navigate_to_details_page();
  return (
    <a
      href={url}
      rel="noopener noreferrer"
      style={{ textDecoration: "none", color: "inherit" }}
      className="featured_article_image_component"
      // onClick={navigate_to_details_page}
    >
      <img
        alt={
          featured_user_contribution?.[0]?.title || "YouTurn User Contribution"
        }
        className="featured_article_image"
        src={image_url || BROKEN_IMAGE}
      />
      <div className="featured_article_thumbnail_overlay_content">
        <div className="featured_article_thumbnail_overlay_title">
          <text
            className={`featured_article_image_component_title ${
              language?.name === "tamil"
                ? "featured_article_image_component_title_ta"
                : ""
            }`}
          >
            {split_featured_content(featured_user_contribution?.[0]?.title)}
          </text>
        </div>
        <div className="featured_article_details_wrapper">
          <div className="featured_article_thumbnail_overlay_view_count">
            <LuEye className="featured_article_view_count" />
            <text className="featured_article_view_count">
              {featured_user_contribution?.[0]?.views}
            </text>
          </div>
          <text className="featured_article_thumbnail_overlay_name_and_date">
            {`${
              featured_user_contribution?.[0]?.user_profile?.name
            } | ${date_format(
              featured_user_contribution?.[0]?.created_date_time ||
                featured_user_contribution?.[0]?.published_date_time
            )} `}
          </text>
          <div>
            <Button className="featured_article_button">
              {featured_user_contribution?.[0]?.record_type === "fact_check"
                ? t("fact_check_button_text")
                : t("article")}
            </Button>
          </div>
        </div>
      </div>
    </a>
  );
};

export default FeaturedUserContributionImageContainer;
