import {
  date_format,
  ensure_https,
  format_view_count,
  get_reading_time,
  size_wise_split_content,
  split_tamil_english_words,
} from "@src/helpers/functions";
import { ROUTES } from "@src/route/my_routes";
import React from "react";
import { useTranslation } from "react-i18next";
import { AiFillClockCircle } from "react-icons/ai";
import { LuEye } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import UserContributionListSkeletonLoading from "./user_contribution_list_skeleton_loading";
import { BROKEN_IMAGE, READING_TAG } from "@src/helpers/image_constants";
import { Button, Typography } from "antd";
import { useDynamicSelector } from "@src/services/redux";
import { retrieve_item } from "@src/helpers/cryptos";
import { readingTime } from "reading-time-estimator";

const AllStoriesUserContribution = (props) => {
  const { t } = useTranslation();
  const { dark_mode } = useDynamicSelector("dark_mode");
  const language = retrieve_item("language");

  const {
    all_stories_list,
    // filtered_item,
    filtered_item,
    handle_page_limit,
    user_contribution_list_loading,
    title,
  } = props;

  const { pagination: user_contribution_pagination } = useDynamicSelector(
    "get_user_contribution_list"
  );

  const navigate = useNavigate();
  const { size } = useDynamicSelector("screen_width");

  const handle_redirect = (item) => {
    if (item?.record_type === "article") {
      const url = `${ROUTES.SINGLE_ARTICLE}/${item?.perma_link}.html`;
      return url;
      // window.open(url, "_blank");
      // navigate(`${ROUTES.SINGLE_ARTICLE}/${item?.perma_link}.html`);
    } else {
      const url = `${ROUTES.SINGLE_FACT_CHECK}/${item?.perma_link}.html`;
      return url;

      // window.open(url, "_blank");
      // navigate(`${ROUTES.SINGLE_FACT_CHECK}/${item?.perma_link}.html`);
    }
  };

  return (
    <div className="all_stories_list_container">
      <h2
        className={
          language?.name === "tamil"
            ? `${dark_mode ? "white_text" : ""} user_contribution_title_ta`
            : `${dark_mode ? "white_text" : ""} user_contribution_title`
        }
      >
        {title}
      </h2>
      {/* <UserContributionTitle title={title} /> */}
      {all_stories_list?.map((item, index) => {
        const image_url = ensure_https(item?.thumbnail_img);
        const url = handle_redirect(item);

        return (
          <div className="all_stories_list_container_card">
            <a
              href={url}
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "inherit" }}
              className="home_article_first_card_container"
            >
              <div className="home_article_card_thumbnail_container">
                <img
                  alt={
                    item?.user_profile?.name ||
                    item?.staff_profile?.name ||
                    "YouTurn "
                  }
                  src={image_url || BROKEN_IMAGE}
                  className="home_article_trending_thumbnail"
                />
              </div>
              <div className="home_article_card_details_wrapper">
                <div className="home_article_card_author_details_wrapper">
                  <div className="home_article_card_author_name_container">
                    <Typography
                      className={`sub_title_text ${
                        dark_mode ? "dark_mode_text" : ""
                      }`}
                    >
                      {item?.user_profile?.name || item?.staff_profile?.name}
                    </Typography>
                  </div>
                  <div className="home_article_card_author_details">
                    <div className="home_article_card_time_container">
                      <LuEye className="view_icon" />
                      <Typography
                        className={`sub_title_text ${
                          dark_mode ? "dark_mode_text" : ""
                        }`}
                      >
                        {format_view_count(item?.views)}
                      </Typography>
                    </div>
                  </div>
                </div>

                <div className="home_article_card_author_details_wrapper">
                  <div className="home_article_card_author_name_container">
                    <Typography
                      className={`sub_title_text ${
                        dark_mode ? "dark_mode_text" : ""
                      }`}
                    >
                      {date_format(item?.published_date_time)}
                    </Typography>
                  </div>
                  <div className="home_article_card_author_details">
                    <div className="home_article_card_time_container">
                      <AiFillClockCircle className="reading_icon" />
                      <Typography
                        className={`sub_title_text ${
                          dark_mode ? "dark_mode_text" : ""
                        }`}
                      >
                        {get_reading_time(item?.reading_time, t)}
                      </Typography>
                    </div>
                  </div>
                </div>
                <div className="home_article_card_title">
                  <Typography
                    className={`title_text  ${
                      dark_mode ? "dark_mode_text" : ""
                    }`}
                  >
                    {split_tamil_english_words(item?.title, "_", 7)}
                  </Typography>
                </div>
                <div className="home_article_card_subtitle">
                  <Typography
                    className={`sub_title_text ${
                      dark_mode ? "dark_mode_text" : ""
                    }`}
                  >
                    {size_wise_split_content(item?.subtitle, size, 7) || ""}
                  </Typography>
                </div>

                <div className="home_article_card_continue_read">
                  <h4 className="continue_reading_text">
                    {t("continue_reading")}
                  </h4>
                  <img
                    alt={"reading_tag"}
                    src={READING_TAG}
                    style={{ height: "30px" }}
                  />
                </div>
              </div>
            </a>
          </div>
        );
      })}
      {user_contribution_list_loading && (
        <UserContributionListSkeletonLoading />
      )}
      <div className="all_stories_button">
        {user_contribution_pagination?.total_count > 0 &&
          user_contribution_pagination?.total_count !==
            all_stories_list?.length && (
            <Button
              style={{
                width: "200px",
              }}
              onClick={handle_page_limit}
              className="load_more_button"
            >
              {t("see_all")}
            </Button>
          )}
      </div>
    </div>
  );
};

export default AllStoriesUserContribution;
