import { retrieve_item } from "@src/helpers/cryptos";
import {
  size_wise_split_content,
  split_featured_content,
} from "@src/helpers/functions";
import { ARROW_GIF_ICON } from "@src/helpers/image_constants";
import { ROUTES } from "@src/route/my_routes";
import { useDynamicSelector } from "@src/services/redux";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const FeaturedArticleContent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let language = retrieve_item("language");
  const { size } = useDynamicSelector("screen_width");

  const { items: featured_article, loading: featured_article_loading } =
    useDynamicSelector("get_featured_article");

  const url = `${ROUTES.SINGLE_ARTICLE}/${featured_article?.[0]?.perma_link}.html`;
  // const navigate_to_detail_page = () => {
  //   window.open(url, "_blank");
  // };
  return (
    <div className="featured_article_content">
      <div className="featured_article_content_title_wrapper">
        <h4 className="featured_story_article">{t("featured_article")}</h4>
        <a
          href={url}
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
          // onClick={navigate_to_detail_page}
          className={
            language?.name === "tamil"
              ? "featured_story_article_title_ta"
              : "featured_story_article_title"
          }
        >
          {split_featured_content(featured_article?.[0]?.title)}
        </a>
      </div>
      <div className="featured_article_content_sub_title_wrapper">
        <a
          href={url}
          rel="noopener noreferrer"
          style={{ textDecoration: "none", color: "inherit" }}
          className={
            language?.name === "tamil"
              ? "featured_article_sub_title_ta"
              : "featured_article_sub_title"
          }
          // onClick={navigate_to_detail_page}
        >
          {size_wise_split_content(featured_article?.[0]?.subtitle, size, 4) ||
            ""}
        </a>
      </div>
      <a
        href={url}
        rel="noopener noreferrer"
        style={{ textDecoration: "none", color: "inherit" }}
        className="featured_article_continue_reading_wrapper"
        // onClick={navigate_to_detail_page}
      >
        <h4 className="featured_story_continue_reading_text">
          {t("continue_reading")}
        </h4>
        <img alt={"arrow_gif"} width={30} height={30} src={ARROW_GIF_ICON} />
      </a>
    </div>
  );
};

export default FeaturedArticleContent;
