import { retrieve_item } from "@src/helpers/cryptos";
import {
  date_format,
  ensure_https,
  format_views_count,
  split_featured_content,
} from "@src/helpers/functions";
import { BROKEN_IMAGE } from "@src/helpers/image_constants";
import { ROUTES } from "@src/route/my_routes";
import { useDynamicSelector } from "@src/services/redux";
import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { LuEye } from "react-icons/lu";
import { useNavigate } from "react-router-dom";

const FeaturedArticleImageContainer = () => {
  const navigate = useNavigate();
  let language = retrieve_item("language");

  const { t } = useTranslation();
  const { items: featured_article, loading: featured_article_loading } =
    useDynamicSelector("get_featured_article");
  const formatted_date = featured_article?.[0]?.published_date_time
    ? date_format(featured_article?.[0]?.published_date_time)
    : date_format(featured_article?.[0]?.created_date_time);

  const navigate_to_detail_page = () => {
    // navigate(
    //   `${ROUTES.SINGLE_ARTICLE}/${featured_article?.[0]?.perma_link}.html`
    // );
    // window.open(url, "_blank");
  };
  const url = `${ROUTES.SINGLE_ARTICLE}/${featured_article?.[0]?.perma_link}.html`;

  const image_url = ensure_https(featured_article?.[0]?.thumbnail_img);

  return (
    <a
      href={url}
      rel="noopener noreferrer"
      style={{ textDecoration: "none", color: "inherit" }}
      className="featured_article_image_component"
      // onClick={navigate_to_detail_page}
    >
      <img
        alt={featured_article?.[0]?.title || "YouTurn Article"}
        className="featured_article_image"
        src={image_url || BROKEN_IMAGE}
      />
      <div className="featured_article_thumbnail_overlay_content">
        <div className="featured_article_thumbnail_overlay_title">
          <text
            className={
              language?.name === "tamil"
                ? "featured_article_image_component_title_ta"
                : "featured_article_image_component_title"
            }
          >
            {split_featured_content(featured_article?.[0]?.title)}
          </text>
        </div>
        <div className="featured_article_details_wrapper">
          <div className="featured_article_thumbnail_overlay_view_count">
            <LuEye className="featured_article_view_count" />
            <text className="featured_article_view_count">
              {format_views_count(featured_article?.[0]?.views)}
            </text>
          </div>
          <text className="featured_article_thumbnail_overlay_name_and_date">
            {`${featured_article?.[0]?.staff_profile?.name} | ${formatted_date} `}
          </text>
          <div>
            <Button className="featured_article_button">{t("article")}</Button>
          </div>
        </div>
      </div>
    </a>
  );
};

export default FeaturedArticleImageContainer;
