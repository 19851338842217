import { retrieve_item } from "@src/helpers/cryptos";
import {
  size_wise_split_content,
  split_featured_content,
} from "@src/helpers/functions";
import { ARROW_GIF_ICON } from "@src/helpers/image_constants";
import { ROUTES } from "@src/route/my_routes";
import { useDynamicSelector } from "@src/services/redux";
import { Skeleton } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const FeaturedFactCheckContent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let language = retrieve_item("language");
  const { items: featured_fact_check } = useDynamicSelector(
    "get_featured_fact_check"
  );

  const { size } = useDynamicSelector("screen_width");
  const url = `${ROUTES.SINGLE_FACT_CHECK}/${featured_fact_check?.[0]?.perma_link}.html`;

  // const handle_redirect = () => {
  //   window.open(url, "_blank");
  // };

  return (
    <div className="featured_fact_check_content">
      <div className="featured_fact_check_content_title_wrapper">
        <h4 className="featured_story_fact_check">{t("featured_story")}</h4>
        <a
          href={url}
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
          className={
            language?.name === "tamil"
              ? "featured_story_article_title_ta"
              : "featured_story_fact_check_title"
          }
          // onClick={handle_redirect}
        >
          {split_featured_content(featured_fact_check?.[0]?.title)}
        </a>
      </div>
      <div className="featured_fact_check_content_sub_title_wrapper">
        <a
          href={url}
          rel="noopener noreferrer"
          style={{ textDecoration: "none", color: "inherit" }}
          className={
            language?.name === "tamil"
              ? "featured_article_sub_title_ta"
              : "featured_story_fact_check_sub_title"
          }
          // onClick={handle_redirect}
        >
          {size_wise_split_content(
            featured_fact_check?.[0]?.subtitle,
            size,
            4
          ) || ""}
        </a>
      </div>
      <a
        href={url}
        rel="noopener noreferrer"
        style={{ textDecoration: "none", color: "inherit" }}
        className="featured_fact_check_continue_reading_wrapper"
        // onClick={handle_redirect}
      >
        <h4 className="featured_story_continue_reading_text">
          {t("continue_reading")}
        </h4>
        <img alt={"arrow_gif"} width={30} height={30} src={ARROW_GIF_ICON} />
      </a>
    </div>
  );
};

export default FeaturedFactCheckContent;
