import { retrieve_item } from "@src/helpers/cryptos";
import {
  FACEBOOK_FILED_LOGO,
  INSTAGRAM_FILED_LOGO,
  LINKEDIN_FILED_LOGO,
  SHARE_CHAT_FILED_LOGO,
  TELEGRAM_FILED_LOGO,
  TWITTER_FILED_LOGO,
  WHATSAPP_FILED_LOGO,
  YOUTUBE_FILED_LOGO,
} from "@src/helpers/image_constants";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";

const FooterThirdColumn = () => {
  const { t } = useTranslation();
  const language = retrieve_item("language");

  const get_year = moment().year();
  let logos = [
    { icon: FACEBOOK_FILED_LOGO, url: "https://www.facebook.com/youturn.in/" },
    {
      icon: WHATSAPP_FILED_LOGO,
      url: "https://api.whatsapp.com/send?phone=916369748351&text=Hi%20You%20Turn!",
    },
    { icon: TWITTER_FILED_LOGO, url: "https://twitter.com/youturn_in" },
    {
      icon: INSTAGRAM_FILED_LOGO,
      url: "https://www.instagram.com/youturn.media/",
    },
    {
      icon: YOUTUBE_FILED_LOGO,
      url:
        language?.name === "tamil"
          ? "https://www.youtube.com/channel/UCCLsLUlxvfdnRwG8_Uh40Ew"
          : "https://www.youtube.com/@youturnenglish5337",
    },
    {
      icon: LINKEDIN_FILED_LOGO,
      url: "https://in.linkedin.com/in/youturn32017",
    },
    {
      icon: TELEGRAM_FILED_LOGO,
      url: "https://t.me/youturn_in",
    },
    {
      icon: SHARE_CHAT_FILED_LOGO,
      url: "https://sharechat.com/profile/youturn_in?referer=tagProfileSearchPage",
    },
  ];
  const handle_redirect = (url) => {
    window.open(url);
  };

  return (
    <div className="footer_third_column_container">
      <div className="social_media_container">
        <h4 className="social_media_tag">
          {t("follow_us_on_our_social_media")}
        </h4>
        <div className="footer_third_column_image_container">
          {logos?.map((logo) => {
            return (
              <div
                className="image_box"
                onClick={() => handle_redirect(logo.url)}
              >
                <img
                  alt={"social media logo"}
                  src={logo.icon}
                  className="footer-logo"
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="copy_right_container">
        <h4 className="copy_right_text">
          ©Copyright {get_year} All Rights Reserved
        </h4>
      </div>
    </div>
  );
};

export default FooterThirdColumn;
