import { Button, Col, Row, Typography } from "antd";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { LuEye } from "react-icons/lu";
import ExposingFakeNewsTitleContent from "./exposing_fake_news_title_content";
import { ROUTES } from "@src/route/my_routes";
import { useNavigate } from "react-router-dom";
import {
  date_format,
  ensure_https,
  split_tamil_english_words,
} from "@src/helpers/functions";
import { BROKEN_IMAGE } from "@src/helpers/image_constants";
import { useDynamicSelector } from "@src/services/redux";
import { retrieve_item } from "@src/helpers/cryptos";

const ExposingFakeNewsFirstColumn = (props) => {
  const { list } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  let language = retrieve_item("language");
  const image_url = ensure_https(list?.[0]?.thumbnail_img);
  const { dark_mode } = useDynamicSelector("dark_mode");
  const url = `${ROUTES.SINGLE_FACT_CHECK}/${list?.[0]?.perma_link}.html`;

  const handle_click_page_redirect = () => {
    // const url = `${ROUTES.SINGLE_FACT_CHECK}/${list?.[0]?.perma_link}.html`;
    // window.open(url, "_blank");
    navigate(`${ROUTES.SINGLE_FACT_CHECK}/${list?.[0]?.perma_link}.html`);
  };

  return (
    <Row className="exposing_fake_news_first_section">
      <ExposingFakeNewsTitleContent />
      <a
        href={url}
        rel="noopener noreferrer"
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <Row className="inner_row_content">
          <img
            alt={list?.[0]?.title || "YouTurn"}
            className="exposing_fake_news_first_column_image"
            src={image_url || BROKEN_IMAGE}
          />
          <Col className="exposing_fake_news_first_column_details">
            <Row className="exposing_fake_news_first_column_details_row">
              <text
                className={
                  language?.name === "tamil"
                    ? "exposing_fake_news_first_column_title_ta"
                    : "exposing_fake_news_first_column_title"
                }
              >
                {split_tamil_english_words(list?.[0]?.title)}
              </text>
            </Row>
            <Row className="exposing_fake_news_first_column_details_wrapper">
              <Col className="exposing_fake_news_first_column_view_count">
                <LuEye className="exposing_fake_news_first_column_view_icon" />
                <Typography
                  className={`exposing_fake_news_first_column_writer_name_and_views ${
                    dark_mode ? "dark_mode_text" : ""
                  }`}
                >
                  {list?.[0]?.views}
                </Typography>
              </Col>
              <Col className="exposing_fake_news_first_column_writer_name_and_views">{`${
                list?.[0]?.staff_profile?.name ||
                list?.[0]?.user_profile?.name ||
                "youturn Editorial"
              } | ${date_format(list?.[0]?.published_date_time)}`}</Col>
              <Col>
                <Button
                  className="exposing_fake_news_first_column_button"
                  // onClick={handle_click_page_redirect}
                >
                  {t("fact_check_button_text")}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </a>
    </Row>
  );
};

export default ExposingFakeNewsFirstColumn;
