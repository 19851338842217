import {
  INSTAGRAM_LOGO,
  LINKEDIN_LOGO,
  TELEGRAM_LOGO,
  THREADS_LOGO,
  TWITTER_LOGO,
  YOUTUBE_LOGO,
  YOUTUBE_OUTLINED,
} from "@src/helpers/image_constants";
import React from "react";
import SocialMediaLogo from "./social_media_logo";
import { useTranslation } from "react-i18next";
import { retrieve_item } from "@src/helpers/cryptos";

const SocialMediaShare = () => {
  const { t } = useTranslation();
  let language = retrieve_item("language");

  return (
    <div className="half_circle_container">
      <div className="half_circle_wrapper">
        <div className="logo_container">
          <div className="logo_wrapper">
            <SocialMediaLogo
              logo={YOUTUBE_OUTLINED}
              media_name={"YouTube"}
              justify_content={"flex-end"}
              url={
                language?.name === "tamil"
                  ? "https://www.youtube.com/channel/UCCLsLUlxvfdnRwG8_Uh40Ew"
                  : "https://www.youtube.com/@youturnenglish5337"
              }
            />
            <SocialMediaLogo
              logo={LINKEDIN_LOGO}
              media_name={"LinkedIn"}
              justify_content={"center"}
              url={"https://in.linkedin.com/in/youturn32017"}
            />
            <SocialMediaLogo
              logo={INSTAGRAM_LOGO}
              media_name={"Instagram"}
              margin_left={"40px"}
              url="https://www.instagram.com/youturn.media/"
            />
          </div>
        </div>
        <div className="social_media_content_container">
          <text className="social_media_first_content">
            {t("dont_miss_anything")}
          </text>
          <text className="social_media_second_content">
            {t("follow_us_on_our_social_media")}
          </text>
        </div>
        <div className="logo_container">
          <div className="logo_wrapper">
            <SocialMediaLogo
              logo={TWITTER_LOGO}
              media_name={"Twitter"}
              margin_left={"40px"}
              url="https://twitter.com/youturn_in"
            />
            <SocialMediaLogo
              logo={TELEGRAM_LOGO}
              media_name={"Telegram"}
              justify_content={"center"}
              url="https://t.me/youturn_in"
              // margin_top={"60px"}
            />
            <SocialMediaLogo
              logo={THREADS_LOGO}
              media_name={"Threads"}
              justify_content={"flex-end"}
              url={
                language?.name === "tamil"
                  ? "https://www.threads.net/@youturn.media"
                  : "https://www.threads.net/@youturn_english"
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialMediaShare;
