import { retrieve_item } from "@src/helpers/cryptos";
import {
  size_wise_split_content,
  split_featured_content,
} from "@src/helpers/functions";
import { ARROW_GIF_ICON } from "@src/helpers/image_constants";
import { ROUTES } from "@src/route/my_routes";
import { useDynamicSelector } from "@src/services/redux";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const FeaturedUserContributionContent = (props) => {
  const { featured_user_contribution } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  let language = retrieve_item("language");
  const { size } = useDynamicSelector("screen_width");

  const navigate_to_details_page = () => {
    if (featured_user_contribution?.[0]?.record_type === "fact_check") {
      const url = `${ROUTES.SINGLE_FACT_CHECK}/${featured_user_contribution?.[0]?.perma_link}.html`;
      return url;

      // window.open(url, "_blank");
      // navigate(
      //   `${ROUTES.SINGLE_FACT_CHECK}/${featured_user_contribution?.[0]?.perma_link}.html`
      // );
    } else {
      const url = `${ROUTES.SINGLE_ARTICLE}/${featured_user_contribution?.[0]?.perma_link}.html`;
      // window.open(url, "_blank");
      return url;
      // navigate(
      //   `${ROUTES.SINGLE_ARTICLE}/${featured_user_contribution?.[0]?.perma_link}.html`
      // );
    }
  };

  const url = navigate_to_details_page();

  return (
    <div className="featured_article_content">
      <div className="featured_article_content_title_wrapper">
        <h4 className="featured_story_article">{t("featured_story")}</h4>
        <a
          href={url}
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
          // className="featured_story_article_title"
          className={
            language?.name === "tamil"
              ? "featured_story_article_title_ta"
              : "featured_story_article_title"
          }
          // onClick={navigate_to_details_page}
        >
          {split_featured_content(featured_user_contribution?.[0]?.title)}
        </a>
      </div>
      <div className="featured_article_content_sub_title_wrapper">
        <a
          href={url}
          rel="noopener noreferrer"
          style={{ textDecoration: "none", color: "inherit" }}
          // className="featured_article_sub_title"
          className={
            language?.name === "tamil"
              ? "featured_article_sub_title_ta"
              : "featured_article_sub_title"
          }
          // onClick={navigate_to_details_page}
        >
          {size_wise_split_content(
            featured_user_contribution?.[0]?.subtitle,
            size,
            4
          ) || ""}
        </a>
      </div>
      <div className="featured_article_continue_reading_wrapper">
        <h4
          className="featured_story_continue_reading_text"
          // onClick={navigate_to_details_page}
        >
          {t("continue_reading")}
        </h4>
        <img alt={"arrow_gif"} width={30} height={30} src={ARROW_GIF_ICON} />
      </div>
    </div>
  );
};

export default FeaturedUserContributionContent;
