import RichText from "@src/views/ui/rich_text";
import React from "react";
import { useTranslation } from "react-i18next";

const Content = (props) => {
  const { type = "lexical", content, set_content, is_disabled = false } = props;
  const { t } = useTranslation();

  return (
    <div>
      <span style={{ color: "#ffffff" }}>{t("Content")}</span>
      <RichText set_content={set_content} content={content} />
    </div>
  );
};

export default Content;
