import {
  ensure_https,
  format_views_count,
  split_tamil_english_words,
} from "@src/helpers/functions";
import { BROKEN_IMAGE, VIDEO_ROUND_ICON } from "@src/helpers/image_constants";
import { useDynamicSelector } from "@src/services/redux";
import React from "react";
import { useTranslation } from "react-i18next";

const FeaturedVideoComponent = () => {
  const { t } = useTranslation();
  const { items: video_list, loading: video_list_loading } =
    useDynamicSelector("get_video_list");

  const { dark_mode } = useDynamicSelector("dark_mode");

  const get_days_since_published_text = (daysSincePublished) => {
    if (daysSincePublished === 0) {
      return t("Today");
    }
    const years = Math.floor(daysSincePublished / 365);
    const months = Math.floor((daysSincePublished % 365) / 30);
    const days = daysSincePublished % 30;
    const yearText = years > 0 ? `${years} ${t("year", { count: years })}` : "";
    const monthText =
      months > 0 ? `${months} ${t("month", { count: months })}` : "";
    const dayText = days > 0 ? `${days} ${t("day", { count: days })}` : "";
    const timeAgo = [yearText, monthText, dayText]
      .filter(Boolean)
      .join(" " + t("and") + " ");
    return timeAgo ? `${timeAgo} ${t("ago")}` : t("Today");
  };

  const image_url = ensure_https(video_list?.[0]?.thumbnails);

  const go_to_youtube_video = () => {
    window.open(
      `https://www.youtube.com/watch?v=${video_list?.[0]?.video_id}`,
      "_blank"
    );
  };

  return (
    <div className="featured_video_component" onClick={go_to_youtube_video}>
      <img
        alt={video_list?.[0]?.title || "YouTurn_video"}
        className="featured_video_image"
        src={image_url || BROKEN_IMAGE}
      />
      <div
        className={`featured_video_thumbnail_overlay_content ${
          !dark_mode
            ? "featured_video_overlay_dark_background dark_mode_text"
            : ""
        }`}
      >
        <img
          alt="video_play_icon"
          className="featured_videos_thumbnail_overlay_play_icon"
          src={VIDEO_ROUND_ICON}
        />
        <div className="featured_videos_thumbnail_overlay_details">
          <text className="feature_video_text">
            {split_tamil_english_words(video_list?.[0]?.title, "_", 7)}
          </text>
          <text
            style={{
              color: "#111",
            }}
            className={"viewed_count_in_videos"}
          >{`${format_views_count(video_list?.[0]?.views)} ${t(
            "views"
          )} | ${get_days_since_published_text(
            Number(video_list?.days_since_published)
          )}`}</text>
        </div>
      </div>
    </div>
  );
};

export default FeaturedVideoComponent;
