import {
  date_format,
  ensure_https,
  split_content,
  split_title,
} from "@src/helpers/functions";
import { BROKEN_IMAGE } from "@src/helpers/image_constants";
import { ROUTES } from "@src/route/my_routes";
import { useDynamicSelector } from "@src/services/redux";
import { Col, Row, Typography } from "antd";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";

const HomeArticleTrendingCard = ({ list }) => {
  const navigate = useNavigate();
  const { dark_mode } = useDynamicSelector("dark_mode");

  const navigate_to_details_page = () => {
    if (list?.record_type === "fact_check") {
      const url = `${ROUTES.SINGLE_FACT_CHECK}/${list?.perma_link}.html`;
      return url;
      // window.open(url, "_blank");
      // navigate(`${ROUTES.SINGLE_FACT_CHECK}/${list?.perma_link}.html`);
    } else {
      const url = `${ROUTES.SINGLE_ARTICLE}/${list?.perma_link}.html`;
      return url;
      // window.open(url, "_blank");
      // navigate(`${ROUTES.SINGLE_ARTICLE}/${list?.perma_link}.html`);
    }
  };
  const image_url = ensure_https(list?.thumbnail_img);
  const url = navigate_to_details_page();

  return (
    <a
      href={url}
      rel="noopener noreferrer"
      className="home_article_trending_container"
      style={{
        textDecoration: "none", // Removes the default anchor underline
        color: "inherit", // Inherit text color to prevent anchor-specific styles
      }}
    >
      <img
        alt={list?.title || "YouTurn Article"}
        src={image_url || BROKEN_IMAGE}
        className="home_article_trending_image"
      />

      <Col className="home_article_trending_details_container">
        <div className="home_article_trending_title_container">
          <Typography
            className={`home_trending_title_text ${
              dark_mode ? "dark_mode_text" : ""
            }`}
          >
            {split_title(list?.title)}
          </Typography>
        </div>
        <div className="home_article_trending_author_container">
          <Typography
            className={`home_trending_author_text ${
              dark_mode ? "dark_mode_text" : ""
            }`}
          >
            {`${
              list?.staff_profile?.name ||
              list?.user_profile?.name ||
              "youturn Editorial"
            } | ${date_format(
              list?.published_date_time || list?.created_date_time
            )}`}
          </Typography>
        </div>
      </Col>
    </a>
  );
};

export default HomeArticleTrendingCard;
