import { retrieve_item } from "@src/helpers/cryptos";
import { useAPIRequest } from "@src/helpers/hooks";
import {
  add_article_book_mark_mutation,
  delete_article_book_mark_mutation,
  get_article_query,
  get_bookmark_query,
} from "@src/services/graphql/graphql_single_article";
import {
  dynamic_clear,
  dynamic_set,
  useDynamicSelector,
} from "@src/services/redux";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SingleArticleBackGroundContainer from "./single_article_details/single_article_back_ground_container";
import SingleArticleDetailsWithDonationContainer from "./single_article_details/single_article_deatils_with_donation_container";
import Breadcrumbs from "../breadcrumb/breadcrumb";
import { ROUTES } from "@src/route/my_routes";
import {
  split_title,
  split_title_for_bread_crumb,
} from "@src/helpers/functions";
import { useDispatch } from "react-redux";
import { Form } from "antd";
import toast from "react-hot-toast";
import ReportModal from "../single_fact_check/single_fact_check_report_donation/report_modal";

const SingleArticleDetails = () => {
  const { perma_link } = useParams();
  const [form] = Form.useForm();
  const user_id = retrieve_item("user_id");
  let language = retrieve_item("language");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const id = perma_link?.replace(".html", "");
  const url = window.location.pathname;

  const {
    id: article_id,
    title,
    error: get_article_error,
  } = useDynamicSelector("get_article");
  const { is_report_modal } = useDynamicSelector("report_modal_state");

  const { is_saved: bookmark_saved } = useDynamicSelector("bookmark_saved");

  const { status: bookmark_saved_status } = useDynamicSelector(
    "add_article_to_saved_items"
  );
  const { status: bookmark_removed_status } = useDynamicSelector(
    "remove_article_from_saved_items"
  );

  const article_title = split_title_for_bread_crumb(title);

  let get_article_request = useAPIRequest("get_article", get_article_query);
  let book_marks_request = useAPIRequest("bookmark_saved", get_bookmark_query);

  let add_book_mark_request = useAPIRequest(
    "add_article_to_saved_items",
    add_article_book_mark_mutation
  );

  let delete_book_mark_request = useAPIRequest(
    "remove_article_from_saved_items",
    delete_article_book_mark_mutation
  );

  useEffect(() => {
    if (get_article_error) {
      dispatch(dynamic_clear("get_article"));
      navigate(ROUTES.PAGE_NOT_FOUND);
    }
  }, [get_article_error]);

  useEffect(() => {
    get_article();
  }, [id]);

  // useEffect(() => {
  //   dispatch(dynamic_set("single_article_id", { id: article_id }));
  // }, [article_id]);

  useEffect(() => {
    if (title) {
      const truncated_title =
        title.length > 60 ? title.substring(0, 60) + "..." : title;

      document.title = truncated_title;
    }
  }, [title]);

  useEffect(() => {
    if (bookmark_saved_status === "Success") {
      get_bookmark();
      // toast.success("Bookmark saved");
      dispatch(dynamic_clear("add_article_to_saved_items"));
    } else if (bookmark_removed_status === "Success") {
      get_bookmark();
      // toast.success("Bookmark removed");
      dispatch(dynamic_clear("remove_article_from_saved_items"));
    }
  }, [bookmark_saved_status, bookmark_removed_status]);

  const get_article = () => {
    get_article_request(
      {
        language_id: language?.id,
        perma_link: id,
      },
      true
    );
  };

  const create_article_book_mark = () => {
    add_book_mark_request(
      {
        article_id: article_id,
      },
      true
    );
  };

  const delete_article_book_mark = () => {
    delete_book_mark_request(
      {
        article_id: article_id,
      },
      true
    );
  };

  const get_bookmark = () => {
    book_marks_request(
      {
        perma_link: id,
        language_id: language?.id,
      },
      true
    );
  };

  const items = [
    {
      path: ROUTES.HOME,
      breadcrumb_name: "Home",
    },
    {
      path: ROUTES.ARTICLES,
      breadcrumb_name: "Articles",
    },
    {
      path: "",
      breadcrumb_name: article_title,
    },
  ];

  const handle_save = () => {
    if (user_id) {
      get_bookmark();
      if (bookmark_saved) {
        delete_article_book_mark();
      } else {
        create_article_book_mark();
      }
    } else {
      const queryParams = new URLSearchParams();
      queryParams.set("type", "bookmark");
      queryParams.set("types", "article");
      queryParams.set("id", article_id);
      queryParams.set("redirecturl", url);
      navigate({
        pathname: "/login",
        search: `?${queryParams.toString()}`,
      });
    }
  };

  return (
    <div>
      <Breadcrumbs items={items} />
      <SingleArticleBackGroundContainer handle_save={handle_save} />
      <SingleArticleDetailsWithDonationContainer handle_save={handle_save} />
      <ReportModal
        model_open={is_report_modal}
        article_id={article_id}
        type={"article"}
        url={url}
      />
    </div>
  );
};

export default SingleArticleDetails;
