import {
  date_format,
  ensure_https,
  size_wise_split_title,
  split_content,
  split_title,
} from "@src/helpers/functions";
import { BROKEN_IMAGE } from "@src/helpers/image_constants";
import { useDynamicSelector } from "@src/services/redux";
import { ROUTES } from "@src/route/my_routes";
import { Typography } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const TrendingArticleCard = (props) => {
  const { list } = props;
  const navigate = useNavigate();
  const image_url = ensure_https(list?.thumbnail_img);
  const { dark_mode } = useDynamicSelector("dark_mode");
  const { size } = useDynamicSelector("screen_width");
  const url = `${ROUTES.SINGLE_ARTICLE}/${list?.perma_link}.html`;
  // const handle_redirect = () => {
  //   window.open(url, "_blank");
  //   navigate(`${ROUTES.SINGLE_ARTICLE}/${list?.perma_link}.html`);
  // };

  return (
    <a
      href={url}
      rel="noopener noreferrer"
      style={{ textDecoration: "none", color: "inherit" }}
    >
      <div className="fact_check_trending_container">
        <img
          alt={list?.title || "YouTurn Article"}
          src={image_url || BROKEN_IMAGE}
          className="fact_trending_image"
        />

        <div className="fact_trending_details_container">
          <div className="home_article_trending_title_container">
            <Typography
              className={`home_trending_title_text ${
                dark_mode ? "dark_mode_card" : ""
              }`}
            >
              {split_title(list?.title)}
            </Typography>
          </div>
          <div className="home_article_trending_author_container">
            <Typography
              className={`home_trending_author_text ${
                dark_mode ? "dark_mode_card" : ""
              }`}
            >
              {`${
                list?.staff_profile?.name ||
                list?.user_profile?.name ||
                "youturn Editorial"
              } | ${
                list?.published_date_time
                  ? date_format(list?.published_date_time)
                  : date_format(list?.created_date_time)
              }`}
            </Typography>
          </div>
        </div>
      </div>
    </a>
  );
};

export default TrendingArticleCard;
