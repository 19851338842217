import { ROUTES } from "@src/route/my_routes";
import { date_format, ensure_https, split_title } from "@src/helpers/functions";
import { Col, Row, Typography } from "antd";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import { BROKEN_IMAGE } from "@src/helpers/image_constants";

const CategoryWiseFactCheckCard = ({ list, type }) => {
  const navigate = useNavigate();
  const image_url = ensure_https(list?.thumbnail_img);

  const formatted_date = list?.published_date_time
    ? date_format(list?.published_date_time)
    : date_format(list?.created_date_time);

  const handle_redirect = () => {
    if (type === "article") {
      const url = `${ROUTES.SINGLE_ARTICLE}/${list?.perma_link}.html`;
      return url;
      // window.open(url, "_blank");
      // navigate(`${ROUTES.SINGLE_ARTICLE}/${list?.perma_link}.html`);
    } else if (type === "fact-check" || type === "fact_check") {
      const url = `${ROUTES.SINGLE_FACT_CHECK}/${list?.perma_link}.html`;
      return url;

      // window.open(url, "_blank");
      // navigate(`${ROUTES.SINGLE_FACT_CHECK}/${list?.perma_link}.html`);
    }
  };

  const url = handle_redirect();

  return (
    <a
      href={url}
      rel="noopener noreferrer"
      style={{ textDecoration: "none", color: "inherit" }}
      className="fact_category_wise_card_container"
    >
      <img
        alt={list?.title || "YouTurn Fact Check"}
        src={image_url || BROKEN_IMAGE}
        className="fact_category_wise_card_image"
      />

      <Col className="fact_category_wise_card_details_container">
        <div className="fact_category_wise_card_title_container">
          <Typography className="fact_category_wise_card_title_text">
            {split_title(list?.title)}
          </Typography>
        </div>
        <div className="fact_category_wise_card_author_container">
          <Typography className="fact_category_wise_card_author_name_text">
            {`${
              list?.staff_profile?.name ||
              list?.user_profile?.name ||
              "youturn Editorial"
            } | ${formatted_date}`}
          </Typography>
        </div>
      </Col>
    </a>
  );
};

export default CategoryWiseFactCheckCard;
