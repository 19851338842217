import React from "react";
import Editor from "vc-editor";

const RichText = (props) => {
  const { type = "lexical", content, set_content, is_disabled = false } = props;
  const handle_change = (html, json) => {
    if (html || json) {
      set_content({ html: html || "", json: html ? json : "" });
    }
  };
  return (
    <Editor
      // json_content={content?.json || ""}
      // html_content={content.html || ""}
      on_change={handle_change}
      is_disabled={is_disabled}
    />
  );
};

export default RichText;
