import React from "react";
import RelatedAndRecentArticles from "@src/views/components/single_article/related_and_recent_articles";
import SingleArticleDetails from "@src/views/components/single_article/single_article_details";
import HomeDonation from "../components/home/home_donation.jsx/home_donation";
import JoinUs from "../components/custom_components/join_us/join_us";
import { Helmet } from "react-helmet";
import { useDynamicSelector } from "@src/services/redux";
import moment from "moment";
const SingleArticle = () => {
  const get_article = useDynamicSelector("get_article");

  return (
    <div>
      <Helmet>
        <script type="application/ld+json">{`
    {
      "@context": "
https://schema.org"
,
      "@type": "NewsArticle",
      "url": "${window.location.href}",
      "publisher": {
        "@type": "Organization",
        "name": "Youturn",
        "logo": "
https://youturn.in/static/media/you_turn_footer_logo.cd77e868503b375860a0.png"
      },
      "headline": "${get_article?.title}",
      "mainEntityOfPage": "${window.location.href}",
      "articleBody": "Director Comey says the probe into last year's US election would assess if crimes were committed.",
      "image": "${get_article?.thumbnail_img}",
      "datePublished": "${moment(
        new Date(get_article?.published_date_time)
      ).format("YYYY-MM-DDTHH:mm:ssZ")}"
    }
  `}</script>
      </Helmet>
      <SingleArticleDetails />
      <div className="donation_card_mobile_view">
        <HomeDonation />
      </div>
      <RelatedAndRecentArticles />
      <JoinUs />
    </div>
  );
};

export default SingleArticle;
