import React from "react";
import { useTranslation } from "react-i18next";
import { NOTIFICATION_ICON, PLAY_ICON } from "@src/helpers/image_constants";
import { retrieve_item } from "@src/helpers/cryptos";
const HomeVideosSubscribeButton = () => {
  const { t } = useTranslation();
  let language = retrieve_item("language");

  const handle_subscribe = () => {
    if (language?.name === "tamil") {
      window.open("https://www.youtube.com/@Youturnmedia", "_blank");
    } else {
      window.open("https://www.youtube.com/@youturnenglish5337", "_blank");
    }
  };
  return (
    <div className="home_videos_subscribe_button" onClick={handle_subscribe}>
      <img
        alt={"play_icon"}
        className="home_videos_subscribe_button_image"
        src={PLAY_ICON}
      />

      <text className="subscribe_btn">{t("subscribe")}</text>
      <img
        alt={"notification_button"}
        className="home_videos_subscribe_button_image"
        src={NOTIFICATION_ICON}
      />
    </div>
  );
};
export default HomeVideosSubscribeButton;
